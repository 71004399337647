<template lang='pug'>
v-dialog(
  v-model="dialog",
  fullscreen,
  transition="dialog-bottom-transition",
  :overlay="false"
)
  v-card
    v-toolbar(color="teciary", dark)
      v-toolbar-title
        .display-1 Ejercicio de Cotización de Petróleo

      v-spacer

      v-btn(icon, @click.native="dialog = false", dark)
        v-icon fa-times
    .container
      .row.justify-content-center
        //- img(src='/static/img/logo.png' alt='CIC')
      br
      .row.justify-center.text-center
        h1 Instrucciones generales
      .row.justify-content-center
        .col
          p.parrafo.
           ALBA y BATIA son dos países en desarrollo. Cada uno produce petróleo a un costo de menos de $ 10 por barril. CAPITA, país sin litorales y vecino de ambos, es un país altamente desarrollado que consume gran cantidad de petróleo. CAPITA debe comprar todo su petróleo a ALBA o BATIA, ya que de otra forma debería pagar un cargo adicional de transportación terrestre de $ 25 por barril.

          p.parrafo.
            Actualmente ALBA y BATIA venden su petróleo a CAPITA en $ 20 por barril. En tanto ambos productores vendan al mismo precio, cada uno puede esperar mantener aproximadamente la mitad del mercado petrolero de CAPITA. Si un productor vende a menor precio que el otro, expandirá su participación de ese mercado e incrementará sus utilidades a expensas del otro. Pero ninguno de los productores puede sacar al otro del comercio petrolero al ofrecer un descuento sobre el precio de venta ofrecido por el otro productor a CAPITA.

          p.parrafo.
            Usted es miembro del Consejo de Cotización de Petróleo de ALBA o de BATIA. Cada mes durante los próximos meses se le pedirá que fije el precio de venta de ese mes por barril de petróleo de su país, a CAPITA. Su objetivo es maximizar las utilidades de su país en la venta de petróleo a CAPITA. A usted no le interesan las utilidades por petróleo del otro país productor.

          p.parrafo.
            La investigación de mercado ha demostrado que la utilidad mensual para su país por venta de petróleo a CAPITA, depende del precio que usted fije y del precio que fije el otro país productor que vende a CAPITA. Sin embargo, la posibilidad de que otros productores entreguen su producción petrolera vía terrestre a CAPITA, imposibilitan fijar un precio de venta mayor a $ 30 por barril.
          p.parrafo.
            Por acuerdos con CAPITA, el precio que fije cada país deberá ser de $10, $20, ó $30. La siguiente gráfica indica la utilidad que ALBA y BATIA pueden obtener en sus ventas de petróleo a CAPITA. Las cantidades indicadas en cada cuadro indican la utilidad generada durante este mes. La cantidad del rincón superior izquierdo indica la utilidad de ALBA en millones de dólares. La cantidad del rincón inferior derecho de cada cuadro es la utilidad de BATIA también indicada en millones de dólares.
      .row.justify-center
        .col.text-center(style="max-width: 700px;")
          v-img(src='@/assets/values.png' alt='Valores')
      .row.justify-content-center
        .col
          p.parrafo.
            ALBA y BATIA tienen una historia de hostilidad mutua y no mantienen relaciones diplomáticas. Cada uno deberá fijar el precio de venta de petróleo del próximo mes para CAPITA sin conocer el precio que el otro productor fijará. Cualquier intento de consulta con el otro país traerá como consecuencia su destitución del Consejo, además de un posible enjuiciamiento por alta traición.

      .row.justify-center
        v-btn.mb-10(dark, @click="dialog = false", color="teciary") Entendido
</template>
<script>
import ModalMixin from '@/layouts/templates/Modal/ModalMixin.js'
export default {
  mixins: [ModalMixin],
  name: 'Case',
  props: ['team', 'active'],
  data () {
    return {
      dialog: false
    }
  },
  mounted () {
    if (this.active) this.dialog = true
  }
}
</script>
<style>
.parrafo {
  text-align: justify;
  font-size: 16px;
}
</style>
