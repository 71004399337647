<template lang='pug'>
.container
  v-simple-table
    template(v-slot:default)
      thead
        tr
          td.headline(rowspan="2")
            strong Ronda
          td.headline.alba--text(rowspan="2")
            strong Alba
          td.headline.batia--text(rowspan="2")
            strong Batia
          td.headline(colspan="2")
            strong Utilidad
          td.headline(colspan="2")
            strong Acumulado
        tr
          td.headline.alba--text
            strong Alba
          td.headline.batia--text
            strong Batia
          td.headline.alba--text
            strong Alba
          td.headline.batia--text
            strong Batia
      tbody
        tr(v-for="r in results", :key="r.ronda")
          td.display-1(:class="Class(0, r.ronda + 1)")
            | {{ r.ronda }}
          td.display-1(:class="Class(r.alba, r.ronda + 1)")
            | {{ r.alba }}
          td.display-1(:class="Class(r.batia, r.ronda + 1)")
            | {{ r.batia }}
          td.display-1(:class="Class(0, r.ronda + 1)")
            | {{ r.result.alba }}
          td.display-1(:class="Class(0, r.ronda + 1)")
            | {{ r.result.batia }}
          td.display-1(:class="Class(0, r.ronda + 1)")
            | {{ r.acumulated.alba }}
          td.display-1(:class="Class(0, r.ronda + 1)")
            | {{ r.acumulated.batia }}

</template>
<script>
export default {
  name: 'teamResults',
  props: ['results'],
  methods: {
    Class (val, round) {
      let clases = ''
      if (val == '10') clases += 'redcolor '
      if (val == '20') clases += 'grencolor '
      if (val == '30') clases += 'bluecolor '
      if (round == 5 || round == 8 || round == 9) clases += 'specialRound'
      return clases
    }
  }
}
</script>
<style>
.specialRound {
  font-weight: 900 !important;
}
</style>
