<template lang='pug'>
v-card.pointer(color='primary lighten-1')
  .container
    v-dialog(v-model="dialog", max-width="350")
      v-card.text-center.pa-5(color="terciary" dark)
        .text-center

        |
        v-card-text.text-center
          p.headline ¿Desea que el precio fijado para la Ronda {{round}}  sea de {{value}} ?
        |
        v-card-actions.justify-space-around
          v-btn(
            color="error",
            @click.native="dialog = false") Cancelar
          |
          v-btn(
            color="success",
            :loading="loading"
            @click.native="send") Confirmar
    .row.justify-center.my-2
      .display-1 Ronda {{round}}
    .row.justify-center.my-2
      v-btn.pointer.ma-2.text-center.align-center(
        dark fab @click="value=10"
        :class="value===10 ? 'terciary':'secondary'"
        )
        .headline 10
      v-btn.pointer.ma-2.text-center.align-center(
        dark fab @click="value=20"
        :class="value===20 ? 'terciary':'secondary'"
        )
        .headline 20
      v-btn.pointer.ma-2.text-center.align-center(
        dark fab @click="value=30"
        :color="value===30 ? 'terciary':'secondary'"
        )
        .headline 30
    .row.justify-center.my-6
      v-btn.success(@click="dialog=true" :disabled="!value")
        .headline Enviar
</template>
<script>
export default {
  name: 'teamForm',
  props: ['round', 'teamid'],
  data () {
    return {
      dialog: false,
      loading: false,
      value: null
    }
  },
  methods: {
    async send () {
      let model = this.$firestore.models.teams
      let r = this.round - 1
      this.loading = true
      let resp = await model.setValue(this.teamid, r, this.value)
      this.loading = false
      this.dialog = false
      console.log(resp)
      this.$alert('success', 'Exito', 'Ronda enviada')
    }

  }
}
</script>
