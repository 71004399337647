<template lang='pug'>
v-expansion-panels(v-model="panel")
  CaseModal(ref="Case", :active="round < -2", :team="team")
  v-expansion-panel
    v-expansion-panel-header
      .display-1 Ronda {{ round }}
    v-expansion-panel-content
      .container
        .row.text-center.justify-center(v-if="round==1")
          .col
            p.parrafo.
              Ahora tienen las siguientes tareas
            p.tparrafo.
              1. Definan el objetivo que quieren alcanzar
            p.parrafo
              | Como miembros de la junta directiva de la empresa estatal de petróleo de su país, deben definir un objetivo claro que guíe las decisiones que tomarán durante su gestión.
            p.subparrafo.
              Este objetivo debe ser representado en una frase breve y sencilla. Por ejemplo: invadirlos, aumentar las ventas, ganarles, minimizar el conflicto, arruinarlos, ganar más dinero, etc.
            p.tparrafo 2. Definan la estrategia que utilizarán
            p.parrafo.
              La estrategia responde a cómo los miembros de la junta directiva alcanzarán el objetivo principal de su gestión.
            p.subparrafo.
              Por ejemplo: invadirlos por aire y por tierra, manejar precios altos, bajar los precios de mercado, etc.
            p.tparrafo 3. Fije el precio del mes 1
            p.parrafo.
              La junta directiva debe decidir el precio de venta de su barril de petróleo entre los precios disponibles:
            p.tparrafo.
              Tienen 7 minutos para hacerlo. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, su precio para el mes 1 se fijará en $20 de manera automática.
        .row.text-center.justify-center(v-if="round==2")
          .col
            .parrafo.
              Tienen 3 minutos para fijar el precio correspondiente al mes 2. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
        .row.text-center.justify-center(v-if="round==3")
          .col
            .parrafo.
              Tienen 3 minutos para fijar el precio correspondiente al mes 3. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
        .row.text-center.justify-center(v-if="round==4")
          .col
            .tparrafo ¡NOTICIA DE ÚLTIMA HORA!
            .tparrafo ¡Ha explotado una turbina de la principal central hidroeléctrica de CÁPITA!
            .parrafo.
              En consecuencia, CÁPITA se verá en la necesidad de comprar más petróleo para mantener su tren de desarrollo como país industrializado. Esta situación inesperada le va a permitir a ALBA y a BATIA, duplicar sus utilidades (mientras dure la crisis).
            .tparrafo Instrucciones
            .parrafo.
              1.-	Los precios se mantienen iguales, pero las utilidades de ambos países se multiplicarán por 2 a partir del próximo mes 4 y hasta nuevo aviso.
            .parrafo.
              2.-	En vista de esta situación, las empresas estatales petroleras han decidido organizar una feria internacional en la que su junta directiva está plenamente autorizada para enviar UN (01) representante, con el fin de negociar con su contraparte los precios del mes 4 y cualquier otra cosa que considere conveniente negociar. Esta reunión iniciará dentro de 5 minutos. Utilice estos 5 minutos para prepararse y decidir en su junta directiva:
            .parrafo.
                - Asistir o no a la feria de negociación.
            .parrafo.
                - Si deciden asistir a la feria, definir todo lo necesario para abordar la negociación.
            .parrafo.
                - Designar al único miembro de la junta directiva que llevará a cabo la negociación.
            .parrafo.
              3.-	Una vez iniciada la feria, dispondrán de un máximo de 5 minutos para llevar a cabo la negociación entre ambos representantes (ALBA y BATIA).
            .parrafo.
              4.-	Terminada la negociación, la junta directiva deberá reunirse y fijar el precio de venta de su barril de petróleo para el mes 4. Para ello, dispondrán de un máximo de 3 minutos.
            .tparrafo.
              El tiempo total en esta fase del ejercicio, es de 13 minutos. Si el precio de venta de su barril de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
            br
            .tparrafo NO COLOQUE EL PRECIO DE ESTE MES, SI ANTES NO HA IDO A NEGOCIAR CON EL OTRO GRUPO
            
        .row.text-center.justify-center(v-if="round==5")
          .col
            .tparrafo ¡NOTICIA DE ÚLTIMA HORA!
            .tparrafo ¡Ha sido reparada la turbina y todo vuelve a la normalidad!
            .parrafo.
              Para el mes 5 sus ganancias ya no se multiplicarán por 2, y se aplicarán las utilidades normales tanto para ALBA como para BATIA. El mercado vuelve a su demanda normal, debido a la recuperación de la producción hidroeléctrica en CÁPITA.
            .parrafo.
              Tienen 3 minutos para fijar el precio correspondiente al mes 5. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
        .row.text-center.justify-center(v-if="round==6")
          .col
            .parrafo.
              Tienen 3 minutos para fijar el precio correspondiente al mes 5. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
        .row.text-center.justify-center(v-if="round==7")
          .col
            .tparrafo ¡NOTICIA DE ÚLTIMA HORA!
            .tparrafo ¡Nueva crisis en CÁPITA!
            .parrafo.
              Han explotado dos turbinas de la principal central hidroeléctrica de CÁPITA.
              Los técnicos vaticinan que el problema durará los dos meses siguientes (7 y 8)
            .tparrafo Instrucciones:
            .parrafo.
              1.- Los precios se mantienen iguales, pero las utilidades de ambos países se multiplicarán por 4 durante los meses 7 y 8. Esto sólo será posible hacerlo si uno de los dos fija un precio más bajo que el otro, tanto en el mes 7 como en el 8. Si ALBA y BATIA fijan el mismo precio en cada mes, ninguno de los dos podrá cuadruplicar su utilidad durante la duración de esta situación especial.
            .parrafo.
              2.- En vista de esta situación, las empresas estatales petroleras han decidido organizar una feria internacional en la que su junta directiva está plenamente autorizada para enviar UN (01) representante, con el fin de negociar con su contraparte los precios de los meses 7 y 8 y cualquier otra cosa que considere conveniente negociar. Esta reunión iniciará dentro de 5 minutos. Utilice estos 5 minutos para prepararse y decidir en su junta directiva:
            .parrafo.
              • Asistir o no a la feria de negociación.
            .parrafo.
              • Si deciden asistir a la feria, definir todo lo necesario para abordar la negociación.
            .parrafo.
              • Designar al único miembro de la junta directiva que llevará a cabo la negociación con el representante de la contraparte.
            .parrafo.
              • Habrá una sola oportunidad de negociar los términos para los meses 7 y 8, por lo que es necesario que se definan las condiciones para ambos meses en esta reunión.
            .parrafo.
              3.- Una vez iniciada la feria, dispondrán de un máximo de 5 minutos para llevar a cabo la negociación entre ambos representantes (ALBA y BATIA).
            .parrafo.
              4.- Terminada la negociación, la junta directiva deberá fijar el precio de venta de su barril de petróleo para el mes 7. Para ello, dispondrán de un máximo de 3 minutos.
            .parrafo.
              El tiempo total en esta fase del ejercicio, es de 13 minutos. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.
            br
            .tparrafo NO COLOQUE EL PRECIO DE ESTE MES, SI ANTES NO HA IDO A NEGOCIAR CON EL OTRO GRUPO
        .row.text-center.justify-center(v-if="round==8")
          .col
            .parrafo.
              Tienen 3 minutos para fijar el precio correspondiente al mes 8. Si el precio de venta de su barrill de petróleo para este mes no se decide en ese lapso de tiempo, se fijará de manera automática el precio fijado por su junta directiva en el mes anterior.

        .row.text-center.justify-center
          .parrafo.
        .row.text-center.justify-center
          v-btn(small, text, @click="$refs.Case.dialog = true") ver caso
</template>
<script>

import CaseModal from './Case'
export default {
  name: 'roundMemo',
  components: { CaseModal },

  data () {
    return {
      panel: 0,
      message: null
    }
  },
  props: ['round', 'team'],
  watch: {
    round (val) {
      this.panel = 0
      // this.getMessage()
    }
  },
  methods: {
    // async getMessage () {
    //   let origin = messages[this.round].text
    //   let text
    //   if (typeof origin === 'function') {
    //     text = await origin()
    //     text = text.data
    //   } else {
    //     text = origin
    //   }
    //   this.message = text
    // }
  },
  mounted () {
    // this.getMessage()
  }
}
</script>
<style>
.tparrafo {
  font-weight: 600;
  text-align: justify;
  font-size: 22px;
}.parrafo {
  text-align: justify;
  font-size: 22px;
}
.subparrafo {
  font-style: italic;
  text-align: justify;
  font-size: 20px;
  font-weight: 250;
}
</style>
