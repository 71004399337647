<template lang="pug">
div
  v-toolbar(:color="temname.toLowerCase()" dark )
    v-spacer
    .display-2.text-center {{ temname }}
    v-spacer
  .container(v-if="team")
    ModalEdit(
    :action="$firestore.models.teams.partialUpdate"
    :instanceID="team.id"
    :editInstance="team"
    ref="Edit")
    v-overlay(
      absolute
      :opacity="0.9"
      :value="waiting")
      LoadingComponent(:msg="'Esperando los resultados de '+ rivalname")

    .row.justify-center
      .col.text-center
        .display-1(v-if="ronda>8")  ¡Rondas culminadas!
        RoundMemo(v-else :round='ronda', :team="team.name")

    .row.justify-space-around(v-if="(!team.estrategia || !team.objetive) && ronda<9")
      .col.text-center(v-editable
        @edit="$refs.Edit.open('objetive','text', $words.get('team_objective'))")
        .headline.mt-5.font-weight-bold
          | {{ $words.get('team_objective') }}
        p {{ teamobj || $words.get('todefine') }}

      .col.text-center(v-editable
        @edit="$refs.Edit.open('estrategia','text', $words.get('teamstrategy'))")
        .headline.mt-5.font-weight-bold
          | {{ $words.get('teamstrategy') }}
        p {{ teamest || $words.get('todefine')  }}

    .row.justify-space-around(v-if="ronda>=9")
      .col.text-center
        .headline.mt-5.font-weight-bold
          | {{ $words.get('team_objective') }}
        p {{ teamobj ||  $words.get('todefine') }}
      .col.text-center
        .headline.mt-5.font-weight-bold
          | {{ $words.get('teamstrategy') }}
        p {{ teamest || $words.get('todefine')  }}

    .row.justify-center
      .col-12.col-sm-10.text-center( v-if="ronda>1")
        TeamResults(:results="sumary")
      .col-sm-6.col-xs-12.text-center(v-if="ronda<9 && (team.estrategia || team.objetive)")
        TeamForm(:round='ronda' :teamid="team.id", )

</template>
<script>
import Results from '@/assets/results'
import RoundMemo from '@/components/team/RoundMemo'
import TeamForm from '@/components/team/TeamForm'
import TeamResults from '@/components/team/TeamResults'
export default {
  name: 'TeamHome',
  components: { RoundMemo, TeamForm, TeamResults },
  data () {
    return {
      team: null,
      rival: null,
      results: null,
      ronda: 0,
      sumary: [],
      waiting: false,
      conection_loss: false
    }
  },
  computed: {
    temname () {
      if (!this.team) return ''
      if (this.team.name == 'A') return 'Alba'
      return 'Batia'
    },
    rivalname () {
      if (!this.team) return ''
      if (this.team.name == 'A') return 'Batia'
      return 'Alba'
    },
    teamobj () {
      if (!this.team) return null
      if (!this.team.objetive) return null
      return this.team.objetive
    },
    teamest () {
      if (!this.team) return null
      if (!this.team.estrategia) return null
      return this.team.estrategia
    }
  },
  methods: {
    async getInfo () {
      let teamID = this.$route.params.id
      let groupResults = await this.$firestore.models.teams.teamResults(teamID)
      this.team = groupResults.me
      this.rival = groupResults.rival
      this.results = groupResults.me.results.map(r => r.negociacion)
      this.refreshSumary()
      // let b = groupResults.batia.results.map(r => r.value)
      // let a = groupResults.alba.results.map(r => r.value)
      // this.sumary = new Results(a, b).fullResults()
      this.refreshStatus()
    },
    refreshMe (data) {
      let team = this.team ? this.team : {}
      this.$set(this, 'team', Object.assign(team, data))
      this.refreshStatus()
      this.refreshSumary()
    },
    refreshRival (data) {
      let rival = this.rival ? this.rival : {}
      this.rival = Object.assign(rival, data)
      this.refreshStatus()
      this.refreshSumary()
    },
    refreshSumary () {
      let a
      let b
      let res
      let idx
      if (this.team.name === 'A') {
        a = this.team.results.map(r => r.value)
        b = this.rival.results.map(r => r.value)
        res = new Results(a, b).fullResults()
        idx = 0
      } else {
        b = this.team.results.map(r => r.value)
        a = this.rival.results.map(r => r.value)
        res = new Results(a, b).fullResults()
        idx = 1
      }
      let sumary = []
      for (let i = 0; i < this.ronda - 1; i++) {
        sumary.push({
          ronda: i + 1,
          alba: a[i],
          batia: b[i],
          result: {
            alba: res.utilidades[i][0],
            batia: res.utilidades[i][1]
          },
          acumulated: {
            alba: res.acumulado[i][0],
            batia: res.acumulado[i][1]
          }
        })
      }
      this.sumary = sumary
    },
    refreshStatus () {
      for (let i = 0; i < 9; i++) {
        this.waiting = false
        this.ronda = i + 1
        if (this.ronda > 8) break
        let team = this.team.results[i].value
        let rival = this.rival.results[i].value
        if (!team) break
        if (!rival) { this.waiting = true; break }
        continue
      }
    }
  },
  async mounted () {
    await this.getInfo()
    const self = this
    this.team.reference.onSnapshot(doc => { self.refreshMe(doc.data()) })
    this.rival.reference.onSnapshot(doc => { self.refreshRival(doc.data()) })
  }
}
</script>
